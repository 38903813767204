<template>
  <div>
    <pageHeader />
    <div id="bookings">
      <v-container fluid>
        <v-row>
          <v-col>
            <div class="head">
              <h2 class="main-title mb-3">حجوزاتى</h2>
              <p class="d-flex align-center">
                <v-icon color="#a53860" small>mdi-checkbox-blank-circle</v-icon>
                <span class="line"></span>
              </p>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <v-col>
            <v-simple-table
              class="orders"
              v-if="bookingList && bookingList.length > 0"
            >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-center main-color">اسم الصالون</th>
                    <th class="text-center main-color">الجلسات</th>
                    <th class="text-center main-color">التاريخ</th>
                    <th class="text-center main-color">الوقت</th>
                    <th class="text-center main-color">الموقع</th>
                    <th class="text-center main-color">الفاتورة</th>
                    <th class="text-center main-color">الحالة</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in bookingList"
                    :key="index"
                    class="text-center"
                  >
                    <td>{{ item.salon }}</td>
                    <td>{{ item.sessions }}</td>
                    <td>{{ item.date }}</td>
                    <td>{{ item.time }}</td>
                    <td>
                      <v-btn
                        v-if="item.type == 'salon'"
                        @click="openLocationDialog(index)"
                        icon
                      >
                        <v-icon color="#a53860">mdi-map-marker</v-icon>
                      </v-btn>
                      <locationDialog
                        :dialog="dialogs[index]"
                        v-if="dialogs[index]"
                        :branch_lat="item.branch_lat"
                        :branch_lng="item.branch_lng"
                        :branch_id="item.branch_id"
                        @closeDialog="closeLocationDialog(index)"
                      />
                      <v-icon color="#4A4949" v-if="item.type == 'home'"
                        >mdi-home</v-icon
                      >
                    </td>
                    <td>
                      <a
                        :href="item.pdf_url"
                        target="_blank"
                        rel="noopener noreferrer"
                        v-if="item.pdf_url && item.status == 'مؤكد'"
                      >
                        <v-icon color="#a53860">mdi-clipboard-list</v-icon>
                      </a>
                    </td>
                    <td>
                      <span>{{ item.status }}</span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <p
              class="text-center"
              v-if="bookingList && bookingList.length == 0 && loaded"
            >
              لا يوجد حجوزات
            </p>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import pageHeader from "@/components/pageHeader.vue";
import locationDialog from "@/components/locationDialog.vue";
import { mapGetters } from "vuex";
export default {
  name: "myBookings",
  components: { pageHeader, locationDialog },
  data() {
    return {
      loaded: false,
      bookingList: [],
      dialogs: [],
    };
  },
  computed: {
    ...mapGetters(["base_url"]),
  },
  methods: {
    openLocationDialog(index) {
      this.$set(this.dialogs, index, true);
    },
    closeLocationDialog(index) {
      this.$set(this.dialogs, index, false);
    },
    getBookingsList() {
      this.loaded = false;
      this.$http
        .get(this.base_url + "/front/myBookings", {
          headers: {
            locale: localStorage.locale,
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.loaded = true;
          this.bookingList = response.data.data;

          for (let i = 0; i < this.bookingList.length; i++) {
            this.dialogs.push(false);
          }
        });
    },
  },
  mounted() {
    this.getBookingsList();
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/_variables.scss";

#bookings {
  margin: 50px 0;
  .head {
    margin: 0 80px;
    .main-title {
      color: $second-color;
      font-size: 2rem;
    }
    .line {
      display: inline-block;
      width: 100%;
      height: 1px;
      background: $second-color;
      margin-right: -3px !important;
    }
  }

  .orders {
    th.text-center {
      border-bottom: 1px solid $main-color;
      font-size: 1.2rem !important;
    }

    td {
      font-size: 1.2rem !important;
      color: #4a4949 !important;
      padding-top: 16px !important;
      padding-bottom: 16px;

      a {
        text-decoration: unset !important;
      }
    }
  }
}

@media (max-width: 600px) {
  #bookings {
    margin: 50px 0;
    .head {
      margin: 0 12px;
    }
  }
}
</style>
