<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      width="50%"
      style="box-shadow: unset !important"
    >
      <v-card id="subscribe-dialog">
        <v-container>
          <v-row>
            <v-col cols="12" sm="6" class="left-col d-none d-sm-block pa-0">
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12" class="text-center">
                    <Map
                      :lat="branch_lat"
                      :lng="branch_lng"
                      page="locationDialog"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-col>
            <v-col cols="12" sm="6" class="right-col pa-0">
              <v-card-text class="px-4 px-sm-8">
                <h2 class="mb-8 text-center">
                  <strong> {{ branchInfo.name }}</strong>
                </h2>
                <div class="login-sec mb-8">
                  <p>{{ branchInfo.formatted_address }}</p>
                </div>

                <div class="close-action text-center mt-4">
                  <v-btn class="main-btn login-btn" @click="closeDialog">
                    اغلاق
                  </v-btn>
                </div>
              </v-card-text>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Map from "../components/Map.vue";
import { mapGetters } from "vuex";

export default {
  name: "subscribeDialog",
  components: { Map },
  props: ["dialog", "branch_lat", "branch_lng", "branch_id"],
  data() {
    return {
      branchInfo: {},
    };
  },
  computed: {
    ...mapGetters(["base_url"]),
  },
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },

    getBranchInfo() {
      this.$http
        .get(this.base_url + "/front/getBranchInfo/" + this.branch_id, {})
        .then((response) => {
          console.log(response);
          this.branchInfo = response.data.data;
        });
    },
  },
  mounted() {
    this.getBranchInfo();
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/_variables.scss";

#subscribe-dialog {
  //   padding: 50px 0 !important;
  background-color: transparent;
  .right-col {
    // height: 630px;
    padding: 100px 0;
    border-top: 5px solid #a53860;
    border-radius: 0 0 0 60px;
    padding: 1rem 3rem;
    background-color: #fff;
    margin-bottom: 10px !important;
    padding-top: 100px !important;
    padding-bottom: 25px !important;
    .appLinks {
      .app-img {
        max-width: 130px !important;
        margin-bottom: 12px;
        margin-right: 5px;
        margin-left: 5px;
        border: 1px solid #ccc;
        border-radius: 12px;
      }
    }
    h2 {
      font-size: 1.5rem !important;
    }
  }
  .left-col {
    // height: 630px;
    border-bottom: 5px solid #fff;
    border-radius: 0 70px 0 0;
    padding: 1rem 2rem;
    background-color: #2d3533;
    margin-top: 10px;

    .slogan {
      position: absolute;
      top: 40%;
      right: 10%;
      font-size: 1.5rem;
      font-weight: bold;
      .slogan1 {
        // font-size: 2rem;
        margin-bottom: 3rem;
      }
      .slogan2 {
        // font-size: 2rem;
      }
    }
  }
}
</style>
